import * as React from "react";
import { Layout, SEO } from "components";
import { Hero } from "components/ACF";
import { graphql } from "gatsby";

interface NotFoundProps {
  data: {
    siteWideSettings: { allSettings: { generalSettingsTitle: string } };
  };
}

const NotFoundPage = ({
  data: {
    siteWideSettings: { allSettings },
  },
}: NotFoundProps): JSX.Element => {
  return (
    <>
      <Layout pageTitle={`404 - ${allSettings.generalSettingsTitle}`}>
        <Hero
          data={{
            content: `<p class="eyebrow">Uhoh!</p><h1>404 - page not found</h1><p>The page you were looking for cant be found. <br />Do you want to return to the <a href="/">homepage</a>?</p>`,
          }}
        />
      </Layout>
    </>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    siteWideSettings: wp {
      allSettings {
        generalSettingsTitle
      }
    }
  }
`;
